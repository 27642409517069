import {
  FETCH_NATIONAL_RATES,
} from './nationalRates';

import {
  FETCH_INTERNATIONAL_RATES,
} from './internationalRates';

import {
  SHOW_LOADER,
  HIDE_LOADER
} from './loader';

export const fetchNationalRates = FETCH_NATIONAL_RATES;
export const fetchInternationalRates = FETCH_INTERNATIONAL_RATES;
export const showLoader = SHOW_LOADER;
export const hideLoader = HIDE_LOADER;