import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const defaultHeaderStyles = theme => ({
    appBar: {
        position: 'relative',
        padding: `0 ${theme.spacing.unit * 3}px`,
    },
    toolbarTitle: {
        flex: 1,
    },
    header: {
        marginBottom: theme.spacing.unit * 4,
    },
    logo: {
        width: 200,
        display: 'table-cell',
        verticalAlign: 'middle'
    }
});

const navItems = [];

class Header extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <header className={classes.header}>
                <AppBar position="static" color="primary" className={classes.appBar}>

                    <Toolbar>
                        <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                            <img src={process.env.PUBLIC_URL + 'logo.svg'} alt="logo" className={classes.logo} />
                        </Typography>
                        {navItems.map(navItem => (
                            <Button variant={navItem.outline} key={navItem.text}>{navItem.text}</Button>
                        ))}
                    </Toolbar>
                </AppBar>
            </header>
        );
    }
}


export default withStyles(defaultHeaderStyles)(Header);