import React from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { fetchNationalRates, fetchInternationalRates } from '../actions';
import Table from './elements/Table';

const defaultRatesStyles = theme => ({
  wrapper: {
    overflowX: 'auto',
    width: 'auto',
    margin: '0 auto',
    borderTop: 'none',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px'
  }
});

class Rates extends React.Component {
  state = { height: 0, dataType: 'national' };

  componentDidMount() {
    const { fetchNationalRates, fetchInternationalRates } = this.props;
    window.addEventListener("resize", this.updateTableDimensions);
    this.updateTableDimensions();
    if(this.state.dataType === 'national') {
      fetchNationalRates();
    } else {
      fetchInternationalRates();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateTableDimensions);
  }

  getAbsoluteHeight = el => {
    // Get the DOM Node if you pass in a string
    el = (typeof el === 'string') ? document.querySelector(el) : el;

    var styles = window.getComputedStyle(el);
    var margin = parseFloat(styles['marginTop']) +
      parseFloat(styles['marginBottom']);

    return Math.ceil(el.offsetHeight + margin);
  }

  updateTableDimensions = () => {
    var otherElementsHeight = this.getAbsoluteHeight(document.querySelector('footer'))
      + this.getAbsoluteHeight(document.querySelector('header')) + this.getAbsoluteHeight(document.querySelector('.' + this.props.classes.wrapper));
    var height = window.innerHeight - otherElementsHeight;

    if (height < 250) {
      height = 250;
    }
    this.setState({ height: height });
  };

  changeDataType = () => {
    const { fetchNationalRates, fetchInternationalRates } = this.props;
    if(this.state.dataType === 'national') {
      this.setState({ dataType: 'international' });
      fetchInternationalRates();
    } else {
      this.setState({ dataType: 'national' })
      fetchNationalRates();
    }
  }

  render() {
    const { classes, rates } = this.props;
    var tableWidth = 670;
    var title = 'Nationale tarieven (excl. BTW)';
    var filename = 'tarieven_nationaal_exclbtw.csv';
    if (this.state.dataType === 'international') {
      tableWidth = 670;
      title = 'Internationale tarieven (excl. BTW)';
      filename = 'tarieven_internationaal_exclbtw.csv'
    }
    var columns = [
      {
        dataKey: 'code_explained',
        label: 'Beschrijving',
        width: 240,
        export: true
      },
      {
        dataKey: 'setup_peak_out',
        label: 'Setup piek',
        width: 107,
        numeric: true,
        export: true
      },
      {
        dataKey: 'price_peak_out',
        label: 'Prijs piek',
        width: 107,
        numeric: true,
        export: true
      },
      {
        dataKey: 'setup_offpeak_out',
        label: 'Setup dal',
        width: 107,
        numeric: true,
        export: true
      },
      {
        dataKey: 'price_offpeak_out',
        label: 'Prijs dal',
        width: 107,
        numeric: true,
        export: true
      }
    ];

    if (this.state.dataType === 'international') {
      columns = [
        {
          dataKey: 'code_explained',
          label: 'Beschrijving',
          width: 400,
          export: true
        },
        {
          dataKey: 'setup_out',
          label: 'Setup',
          width: 130,
          numeric: true,
          export: true
        },
        {
          dataKey: 'price_out',
          label: 'Prijs',
          width: 130,
          numeric: true,
          export: true
        }
      ];
    }

    return ([
      <Paper key="tabs" className={classes.wrapper} style={{
        marginBottom: 20, maxWidth: tableWidth
      }}>
        <Tabs
          value={this.state.dataType}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.changeDataType}
        >
          <Tab label="Nationaal" value="national" />
          <Tab label="Internationaal" value="international" />
        </Tabs>
      </Paper>,
      <Paper key="table" className={classes.wrapper} style={{
        height: this.state.height, maxWidth: tableWidth
      }}>
        <Table
          excludedComponents={[]}
          title={title}
          usesSelection={false}
          src={rates}
          sortBy={'code_explained'}
          sortDirection={'ASC'}
          desiredWidth={tableWidth}
          columns={columns}
          filename={filename}
        />
      </Paper>]
    );
  }
}

const mapStateToProps = state => {
  return { rates: state.rates };
};

export default withStyles(defaultRatesStyles)(connect(
  mapStateToProps,
  { fetchNationalRates, fetchInternationalRates }
)(Rates));
