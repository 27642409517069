import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      light: '#ffbb00',
      main: '#ffbb00',
      dark: '#ffbb00',
    },
    secondary: {
      light: '#232323',
      main: '#232323',
      dark: '#232323',
    },
    textSecondary: {
      light: '#fff',
      main: '#fff',
      dark: '#fff'
    }
  },
  typography: {
    useNextVariants: true,
  },
});