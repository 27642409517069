import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const defaultFooterStyles = theme => ({
    footer: {
        marginTop: theme.spacing.unit * 4,
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 6}px`,
        backgroundColor: '#232323'
    },
    footerText: {
        color: 'white',
        fontSize: 14
    }
});


class Footer extends React.Component {
    render() {

        const { classes } = this.props;
        const date = new Date();

        return (
            <footer className={classes.footer}>
                <Typography variant="h6" className={classes.footerText} gutterBottom>
                    © {date.getFullYear()} Weepee NV
                </Typography>
            </footer>
        );
    }
}


export default withStyles(defaultFooterStyles)(Footer);