import React from 'react';
import Grow from '@material-ui/core/Grow';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { withStyles } from '@material-ui/core/styles';
import { CSVLink } from "react-csv";
import IconButton from '@material-ui/core/IconButton';

const defaultExportStyles = {
    download: {
        flex: '0 0 auto',
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '12px',
        overflow: 'visible',
        fontSize: '1.5rem',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            color: 'rgba(0, 0, 0, 0.54)'
        }
    },
};

class TableExport extends React.Component {
    render() {
        const { classes, columns, src, filename } = this.props;
        var headers = [];
        columns.forEach(function (column) {
            if (column.label !== 'selection' && column.export) {
                headers = [...headers, { key: column.dataKey, label: column.label }]
            }
        });

        return (<Grow appear in={true} timeout={300}>
            <CSVLink data={src} headers={headers} filename={filename} aria-label="Download">
                <IconButton className={classes.button} aria-label="Search">
                    <DownloadIcon />
                </IconButton>
            </CSVLink>
        </Grow>);
    }
}

export default withStyles(defaultExportStyles)(TableExport);