import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { AutoSizer, Column, SortDirection, Table } from 'react-virtualized';
import Checkbox from '@material-ui/core/Checkbox';
import Toolbar from './Table/Toolbar';

const defaultTableStyles = theme => ({
  table: {
    fontFamily: theme.typography.fontFamily,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
    fontSize: 14
  },
  noClick: {
    cursor: 'initial',
  },
});

class MuiVirtualizedTable extends React.Component {

  state = {
    searchQuery: '',
    selected: [],
    sortBy: '',
    sortDirection: 'ASC'
  };

  componentDidMount() {
    const { sortBy, sortDirection } = this.props;

    this.setState({ sortBy, sortDirection });
  }
  getRowClassName = ({ index }) => {
    const { classes, rowClassName, onRowClick } = this.props;

    return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer = ({ cellData, columnIndex = null }) => {
    const { classes, onRowClick, rowHeight, columns, usesSelection } = this.props;
    const selected = this.state.selected;
    if (usesSelection && columns[columnIndex]['label'] === 'selection') {
      cellData = <Checkbox
        color="primary"
        checked={
          selected.some(selectedID => cellData === selectedID)
        }
      />;
    }
    return (
      <TableCell
        component="div"
        className={classNames(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
      >
        {cellData}
      </TableCell>
    );
  };

  onSearch = searchQuery => {
    this.setState({ searchQuery });
  };

  dynamicSort = (property, direction) => {
    var sortOrder = 1;
    if (direction === "DESC") {
      sortOrder = -1;
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  processMassSelection = src => {
    const selected = this.state.selected;
    if (selected.length === src.length) {
      this.setState({ selected: [] });
    } else {
      const selectAll = src.map(d => d.code);
      this.setState({ selected: selectAll });
    }
  }

  headerRenderer = ({ src, label, columnIndex, dataKey }) => {
    const { headerHeight, columns, classes, usesSelection } = this.props;
    const sortBy = this.state.sortBy;
    const sortDirection = this.state.sortDirection;
    const rowCount = src.length;
    const selected = this.state.selected;
    const direction = {
      [SortDirection.ASC]: 'asc',
      [SortDirection.DESC]: 'desc',
    };
    var inner;
    if (usesSelection && label === 'selection') {
      inner = <Checkbox
        color="primary"
        checked={selected.length > 0}
        onChange={
          () => this.processMassSelection(src)
        }
        {...selected.length > 0 &&
        selected.length !== rowCount && {
          indeterminate: true,
          color: 'default'
        }}
      />;
    } else {
      inner =
        !columns[columnIndex].disableSort && sortBy ? (
          <TableSortLabel active={dataKey === sortBy} direction={direction[sortDirection]}>
            {label}
          </TableSortLabel>
        ) : (
            label
          );
    }
    return (
      <TableCell
        component="div"
        className={classNames(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? 'right' : 'left'}
      >
        {inner}
      </TableCell>
    );
  };

  render = () => {
    const { classes, columns, usesSelection, title, src, primaryKey, excludedComponents, onEdit, desiredWidth, filename, ...tableProps } = this.props;
    const selected = this.state.selected;
    var source = src;
    if (this.state.searchQuery !== '' && this.state.searchQuery.length > 2) {
      source = src.filter(element => {
        return Object.keys(element).some(key => element[key].toString().toLowerCase().search(this.state.searchQuery.toLowerCase()) !== -1);
      });
    }

    const rowCount = source.length;
    if (usesSelection && columns[0]['label'] !== 'selection') {
      columns.unshift({
        dataKey: primaryKey,
        label: 'selection',
        width: 96,
        disableSort: true,
        edit: false
      });
    }
    return (
      [<Toolbar title={title} columns={columns}
        primaryKey={primaryKey} src={source} onEdit={onEdit} excludedComponents={excludedComponents}
        onSearch={this.onSearch} key="tb1" selected={selected} filename={filename} />,
      <AutoSizer key="tbl1">
        {({ height, width }) => (
          <div style={{ height: { height }, width: { width } }}>
            <Table
              className={classes.table}
              height={height - 65}
              width={desiredWidth}
              rowCount={rowCount}
              {...tableProps}
              rowClassName={this.getRowClassName}
              sort={(sort) => {
                source.sort(this.dynamicSort(sort.sortBy, sort.sortDirection));
                this.setState({ sortBy: sort.sortBy, sortDirection: sort.sortDirection });
              }}
              rowGetter={({ index }) => source[index]}
              sortBy={this.state.sortBy}
              sortDirection={this.state.sortDirection}
              onRowClick={(event) => {
                if (usesSelection) {
                  var newSelected = selected;
                  if (selected.some(code => event.rowData.code === code)) {
                    newSelected = selected.filter(code => code !== event.rowData.code);
                  } else {
                    newSelected = [...selected, event.rowData.code]
                  }
                  this.setState({ selected: newSelected });
                }
              }
              }
            >
              {columns.map(({ cellContentRenderer = null, className, dataKey, ...other }, index) => {
                let renderer;
                if (cellContentRenderer != null) {

                  renderer = cellRendererProps =>
                    this.cellRenderer({
                      cellData: cellContentRenderer(cellRendererProps),
                      columnIndex: index,
                    });
                } else {
                  renderer = this.cellRenderer;
                }
                return (
                  <Column
                    key={dataKey}
                    headerRenderer={(headerProps) =>
                      this.headerRenderer({
                        ...headerProps,
                        columnIndex: index,
                        src: source
                      })
                    }
                    className={classNames(classes.flexContainer, className)}
                    cellRenderer={renderer}
                    dataKey={dataKey}
                    {...other}
                  />
                );
              })}
            </Table>
          </div>
        )}
      </AutoSizer>]
    );
  }
}

MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cellContentRenderer: PropTypes.func,
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    }),
  ).isRequired,
  headerHeight: PropTypes.number,
  rowClassName: PropTypes.string,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  excludedComponents: PropTypes.array.isRequired
};

MuiVirtualizedTable.defaultProps = {
  headerHeight: 56,
  rowHeight: 56,
};

export default withStyles(defaultTableStyles)(MuiVirtualizedTable);