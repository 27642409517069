import React from 'react';
import { connect } from 'react-redux';
import Rates from './Rates';
import Header from './elements/Header';
import Footer from './elements/Footer';
import Loader from './elements/Loader';

class App extends React.Component {
    render = () => {
        const { loader } = this.props;
        return (
            <div className="site">
                <Loader loading={loader.loading} />
                <div className="site-content">
                    <Header />
                    <main>
                        <div className="ui container">
                            <Rates />
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        );
    }
};

const mapStateToProps = state => {
    return { loader: state.loader };
};

export default connect(
    mapStateToProps,
    {}
)(App);
