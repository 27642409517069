import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import GridList from '@material-ui/core/GridList';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Export from './Export';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Search from './Search';

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
        height: 64
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.primary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    }, gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    }
});

class TableToolbar extends React.Component {

    state = {
        showTitle: true
    }
    onSearch = searchTerm => {
        this.props.onSearch(searchTerm);
    };

    toggleTitle = show => {
        this.setState({ showTitle: show });
    }
    render = () => {
        const { selected, title, classes, columns, src, filename } = this.props;
        const numSelected = selected.length;
        return (
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
                id={'tableToolbar'}
            >
                {this.state.showTitle ? (
                    [<div key="title" className={classes.title}>
                        {numSelected > 0 ? (
                            <Typography color="inherit" variant="subtitle1">
                                {numSelected} selected
          </Typography>
                        ) : (
                                <Typography variant="h6" id="tableTitle">
                                    {title}
                                </Typography>
                            )}
                    </div>,
                    <div className={classes.spacer} key="spacer" />]
                ) : ''}
                <div className={classes.actions}>
                            <GridList className={classes.gridList} cols={2.5}>
                                <Search onSearch={this.onSearch} toggleTitle={this.toggleTitle} />
                                <Export columns={columns} src={src} filename={filename} />
                            </GridList>
                </div>
            </Toolbar>
        );
    }
};

TableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    selected: PropTypes.array.isRequired,
    excludedComponents: PropTypes.array.isRequired
};

export default withStyles(toolbarStyles)(TableToolbar);