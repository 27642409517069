import ratesAPI from '../apis/internationalAPI';

export const FETCH_INTERNATIONAL_RATES = () => {
    return dispatch => {
        dispatch({ type: 'SHOW_LOADER', payload: { loading: true } })
        return ratesAPI.get('/rates')
            .then(response => {
                dispatch({ type: 'HIDE_LOADER', payload: { loading: false } })
                dispatch({ type: 'FETCH_INTERNATIONAL_RATES', payload: response.data })
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: 'HIDE_LOADER', payload: { loading: false } })
            });
    };
};