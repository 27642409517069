import ratesAPI from '../apis/nationalAPI';

export const FETCH_NATIONAL_RATES = () => {
    return dispatch => {
        dispatch({ type: 'SHOW_LOADER', payload: { loading: true } })
        return ratesAPI.get('/rates')
            .then(response => {
                dispatch({ type: 'HIDE_LOADER', payload: { loading: false } })
                dispatch({ type: 'FETCH_NATIONAL_RATES', payload: response.data })
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: 'HIDE_LOADER', payload: { loading: false } })
            });
    };
};