import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const defaultLoaderStyles = theme => ({
    main: {
            zIndex: 1101,
            backgroundColor: 'rgba(255,255,255,0.8)',
            width: '100%',
            height: '100%',
            position: 'absolute',
            left:0,
            top: 0
    },
    loader: {
        position: 'absolute',
        left: '47%',
        top: '47%',
        zIndex: 1102,
        textAlign: 'center'
    }
});


class Loader extends React.Component {
    render() {
        const { classes, loading } = this.props;
        if(loading) {
        return (
            <div className={classes.main}>
                <CircularProgress size={80} className={classes.loader} />
            </div>
        );
        } else {
            return (<div></div>);
        }
    }
}


export default withStyles(defaultLoaderStyles)(Loader);